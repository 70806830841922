@import "shared";

#footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--white);
  border-top: 1px solid var(--primary);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
  z-index: 1000;

  @include mobile {
    flex-direction: column;
    align-items: center;
    gap: 0;

    #footer-row {
      display: flex;
      flex-direction: row;

      #first-column,
      #second-column,
      #third-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 33%;

        .title {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }

    .footer-row {
      .footer-column {
        .title {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }

    #footer-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        padding-bottom: 0.5rem;
      }

      .socials i {
        padding: 0 0.5rem;
      }
    }
  }

  #first-column {
    min-height: 100%;

    #footer-copyright {
      align-self: flex-start;

      span {
        display: flex;
        height: 100%;

        @include mobile {
          text-align: center;
        }
      }
    }
  }

  #second-column {
    #socials {
      padding-top: 2rem;
    }
  }

  #third-column {
    #footer-letter-block {
      width: 100%;
    }
  }

  .footer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    align-self: normal;

    &.columns,
    &.metadata {
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;

      & > div {
        display: flex;
        flex: 1 1 33%;
      }

      #socials {
        justify-content: center;
        width: 100%;
        gap: 2rem;
      }
    }
    &.metadata {
      & > div {
        padding: 2rem;
      }
    }

    @include mobile {
      &.columns,
      &.metadata {
        gap: 0;
      }

      &.metadata {
        align-items: center;
        flex-direction: column;
        padding: 0 4rem;
        gap: 0.5rem;

        & > div {
          justify-content: center;
        }

        #socials {
          gap: 1rem;
        }
      }
    }
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    @include mobile {
      width: 100%;
      align-items: flex-start;
      margin-bottom: 0;
    }

    .title {
      font-size: 1.3rem;
      font-weight: 500;
      align-self: center;
      text-align: center;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      background-repeat: no-repeat;
      background-position: left center;
      text-decoration: underline;
      cursor: pointer;
      padding-left: 2rem;
      margin-top: 1rem;
      overflow-wrap: break-word;
    }
  }

  #socials {
    i {
      cursor: pointer;
    }

    @include mobile {
      flex-direction: row;
    }
  }

  #footer-input {
    @include mobile {
      flex-direction: row;
    }
    input {
      @include mobile {
        margin: 0;
        max-width: 99%;
      }
    }

    input::placeholder {
      font-size: 1rem;

      @include mobile {
        font-size: 0.8rem;
      }
    }
  }

  #footer-newsletter {
    @include mobile {
      padding: 6px;
      font-size: 0.6rem;
    }
  }

  #footer-newsletter:disabled {
    background-color: var(--fourth);
    cursor: not-allowed;
    min-width: 30%;
    color: var(--white);
  }
}
#footer-legal {
  padding: 1rem;
  background: var(--white);
  z-index: 1;
}
