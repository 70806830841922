@import "shared";

.get-started-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  align-items: center;

  @include mobile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .get-started-content {
    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      color: var(--white);
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.3rem;
      color: var(--white);
      margin-bottom: 2rem;
    }

    .get-started-buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      max-width: 300px;

      @include mobile {
        margin: 0 auto;
        flex-direction: column;
      }

      button {
        width: 100%;
        background: var(--white);
        color: var(--primary);
        border: 1px solid var(--primary);
        padding: 0.8rem 2rem;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: var(--lightPurple);
        }
      }
    }
  }

  .get-started-image {
    img {
      width: 400px;
      height: auto;

      @include mobile {
        width: 200px;
        margin: 2rem auto 0;
      }
    }
  }
}

.why-choose-dscr {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0rem;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 2rem;
  }

  .benefits-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    border: 1px solid var(--sixth);
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--white);

    .benefit-item {
      font-size: 1.4rem;
      color: var(--primary);
      padding: 0.5rem 0;
    }
  }

  .connect-with-str-lender_button {
    margin-top: 2rem;
    padding: 1rem 2rem;
    background: var(--sixth);
    color: var(--white);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background: var(--quinary);
    }
  }
}

.when-conventional {
  max-width: 1200px;
  margin: 0rem auto;
  padding: 0rem;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 2rem;
  }

  .loan-types {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: left;
    border: 1px solid var(--sixth);
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--white);

    .loan-type {
      font-size: 1.4rem;
      color: var(--primary);
      padding: 0.5rem 0;
    }
  }

  .connect-with-str-lender_button {
    margin-top: 2rem;
    padding: 1rem 2rem;
    background: var(--quinary);
    color: var(--white);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background: var(--sixth);
    }
  }
}

.dscr-conventional-tables {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 6rem 2rem;
  gap: 8rem;
  background-color: var(--lightPurple);
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  .conventional-dscr-table {
    @include desktop {
      display: block;
      width: 100%;

      h2 {
        text-align: center;
      }

      table {
        min-width: 800px;
        width: 100%;
        border-collapse: collapse;

        thead {
          tr {
            th:first-of-type {
              font-weight: 600 !important;
            }
            
            th {
              padding: 1rem 2rem;
              text-align: left;
              white-space: nowrap;
              border-bottom: 4px solid var(--sixth);
              background-color: var(--lightPurple);
              
              &:first-child {
                position: sticky;
                left: 0;
                z-index: 2;
              }
              
              &:nth-child(2) {
                left: 200px;
              }
            }
          }
        }

        tbody {
          tr {
            td:first-of-type {
              font-weight: 600 !important;
            }
            
            td {
              padding: 1rem 2rem;
              text-align: left;
              vertical-align: top;
              white-space: normal;
              word-wrap: break-word;
              border-bottom: 1px solid var(--fourth);
              background-color: var(--lightPurple);
              
              &:first-child {
                position: sticky;
                left: 0;
                z-index: 2;
              }
              
              &:nth-child(2) {
                left: 200px;
              }
            }
          }
        }
      }
    }

    @include mobile {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: thin;
      scrollbar-color: var(--black) transparent;

      &::-webkit-scrollbar {
        height: 5px;
        display: block;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--black);
        border-radius: 2.5px;
      }

      table {
        min-width: 800px;

        th, td {
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
        }

        th {
          font-size: 1rem;
        }

        th:first-child,
        td:first-child {
          position: sticky;
          left: 0;
          background-color: var(--lightPurple);
          z-index: 2;
          min-width: 170px;
          max-width: 170px;
          height: 100%;
          vertical-align: middle;
          rowspan: 2;
        }
      }
    }

    table {
      min-width: 800px;
      width: 100%;
      
      thead {
        tr {
          th:first-of-type {
            font-weight: 600 !important;
          }
          
          th {
            padding: 1rem 0.5rem;
            text-align: left;
            white-space: nowrap;
            border-bottom: 4px solid var(--sixth);
            background-color: var(--lightPurple);
            
            &:first-child {
              position: sticky;
              left: 0;
              z-index: 2;
            }
            
            &:nth-child(2) {
              left: 200px;
            }
          }
        }
      }

      tbody {
        tr {
          td:first-of-type {
            font-weight: 600 !important;
          }
          
          td {
            padding: 1rem 0.5rem;
            text-align: left;
            vertical-align: top;
            white-space: nowrap;
            border-bottom: 1px solid var(--fourth);
            
            &:first-child {
              background-color: var(--lightPurple);
            }
          }
        }
      }
    }
  }

  #compareimage {
    display: none;
  }
}
#left-content {
  text-align: left;
  max-width: 1000px;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--sixth);
    margin-bottom: 2rem;
    // text-decoration: underline;
    line-height: 1.2;
    margin-bottom: 0;

    @include mobile {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--primary);
    margin-bottom: 3rem;
    line-height: 1.5;

    @include mobile {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
}
.tools-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 85vw;
    margin: 0 auto 5rem auto;
    padding: 0 1rem;

    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
      grid-template-columns: 1fr;
      padding: 0rem;
    }

    .tool-card {
      background: var(--white);
      border-radius: 0.5rem;
      padding: 2.5rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      border: 1px solid var(--sixth);
      box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background: var(--lightPurple);
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 1rem;
      }

      .tool-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        h3 {
          color: var(--primary);
          margin-bottom: 0.5rem;
          font-size: 1.7rem;
          font-weight: 700;
        }

        p {
          color: var(--primary);
          margin-bottom: 1rem;
          font-size: 1rem;
          line-height: 1.5;
          flex: 1;
        }

        a {
          color: var(--sixth);
          text-decoration: none;
          font-weight: 700;
          font-size: 1.2rem;
          display: block;
          margin-top: auto;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
.testimonial {
  background-size: cover;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  min-height: 300px !important;
  background: var(--white);
  border: 1px solid var(--lightPurple);
  box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
  box-sizing: border-box;
  text-align: center;
  width: 100%;

  > * {
    margin-bottom: 1rem;
  }

  > *:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }

  > Text {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  .stars {
    color: var(--sixth);
    margin: 1rem 0;
    font-size: 2.5rem;
  }

  a {
    background: #e11818;
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
}

.slick-prev,
.slick-next {
  font-size: 20px;
  color: #e11818;
}
.slick-track {
  margin-bottom: 2rem;
}

.slick-prev:hover,
.slick-next:hover {
  color: #000;
}
.slick-dots {
  bottom: -2rem;
  padding: 1rem;
}
.slick-list {
  max-width: 100vw;
  padding: 0px !important;
  margin-bottom: 4rem;
}

.slick-dots li button:before {
  color: #666;
  font-size: 14px;
}

.slick-dots li.slick-active button:before {
  color: #000;
}
#home-statistics {
  max-width: unset;
  display: flex;
  justify-content: space-evenly;

  @include mobile {
    flex-direction: column;
    justify-content: unset;
    margin-top: -5rem;
  }

  @include tablet {
    justify-content: space-between;
  }

  .stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;

    #highlight {
      font-size: 5rem;
      font-weight: 500;
      color: var(--white);
      margin: -10px 0;

      @include tablet {
        font-size: 3rem;
      }

      @include mobile {
        font-size: 3.8rem;
      }
    }

    #alt-red {
      color: var(--white);
      font-size: 2rem;
      font-weight: 500;

      @include tablet {
        font-size: 1.2rem;
      }

      @include mobile {
        font-size: 1.6rem;
        text-align: center;
      }
    }

    #alt-black {
      color: var(--white);
      font-size: 2rem;
      font-weight: 500;

      @include tablet {
        font-size: 1.2rem;
      }

      @include mobile {
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }

  #verdiv {
    @include mobile {
      display: none !important;
    }
  }

  #hordiv {
    display: none !important;

    @include mobile {
      display: block !important;
    }
  }
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 95vw;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .testimonials-container {
    grid-template-columns: 1fr;
    display: block;
    
  }
}
