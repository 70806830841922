@import 'shared';
#home-markets {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;

    @include mobile {
      > img[alt="decoration"]:nth-child(1) {
        left: -6rem !important;
        display: none;
      }

      > img[alt="decoration"]:nth-child(2) {
        right: -7rem !important;
        display: none;
      }   
    }


    #home-markets__header {
      font-weight: 500;
    }

    #home-markets__description {
      max-width: 50%;
      text-align: center;
      font-size: 1.6rem;
      font-weight: normal;

      @include mobile {
        max-width: 100%;
        font-size: 1.2rem;
        display: none;
      }
    }

    #home-markets__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;

      @include desktop {
        flex-direction: row;
        max-width: 85%;
      }

      #left-main {
        flex-basis: 60%;
        max-width: 100%;

        >img {
          max-width: 99.2%;
          padding: 0.4rem;
          background-color: var(--white);
          box-shadow: 8px 8px 0px 0px var(--quaternary);

          @include mobile {
            max-width: 98.5%;
          }
        }
      }

      #right-main {
        flex-basis: 40%;
        max-width: 100%;
        padding: 2rem;

        @include tablet {
          max-width: 80%;
          justify-content: center;
        }

        input {
          padding: 0.7rem;
          background-color: var(--lightPurple);
        }

        * {
          width: 100%;
        }

        #disclaimer {
          font-size: 0.8rem;
          line-height:0%;
          padding: 0;
        }

        #free-download:disabled {
          background-color: var(--fourth);
          cursor: not-allowed;
        }

        button {
          margin-top: 2.5rem;
          padding: 1rem;
        }
      }
    }
  }