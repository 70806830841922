// BestAirbnbs.scss
@import 'shared';

.best-airbnbs_main {
  #no-market {
    z-index: 100;
    width: 100%;
    max-width: 1200px;
  
    @include desktop {
      display: block;
    }
  
    #skeleton {
      @include mobile {
        display: flex !important;
        flex-direction: column;
        align-items: center;
  
        #no-market_intro {
          margin: 0 auto 4rem;
        }
      }
    }
  
    #no-market_intro {
      width: 100%;
      margin-bottom: 4rem;
      font-weight: 300;

      @include mobile {
        width: 100%;
      }
    }
  
    .featured-section {
  
      @include mobile {
        .featured {
          margin: 0px;
  
          div {
            flex-basis: 100%;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  
  .search-top-airbnbs {
    @media (min-width: $breakpoint-mobile) and (max-width: 920px) {
      margin-bottom: -2rem !important;
    }

    div:nth-child(1) {
      @include tablet {
        display: flex;
      }
    }
  }
  
  .search-image {
    position: relative;
    top: 70%;
    left: 0;
    // z-index: 101;

    @include mobile {
      position: absolute;
      z-index: -1;
    }

    &._bg {
      z-index: -1;
    }
  }
  
  #location-container {
    display: flex;
    flex-direction: column;
    margin: 0px;

    @include desktop {
      display: block;
    }
  
    span:nth-child(2) {
      @media (max-width: 1237px) {
        display: none;
      }
    }
  
    div:nth-child(3) {
      width: 80%;
    }
  }
}
