// Breakpoints
$breakpoint-mobile: 720px; // Mobile devices
$breakpoint-tablet: 1024px; // Tablet devices

@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile + 1) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-tablet + 1) {
    @content;
  }
}

// COMMON
.hide-mobile {
  @include mobile {
    display: none !important;
  }
}

.hide-tablet {
  @include tablet {
    display: none !important;
  }
}

.hide-desktop {
  @include desktop {
    display: none !important;
  }
}

.scroll-up-button {
  z-index: 100000 !important;
}
