@import 'shared';

#main-navigation {
  max-width: 100%;
  padding: 0 2.5%;

  @include mobile {
    max-width: 100%;
    justify-content: space-between
  }
}
