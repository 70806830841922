@import 'shared';

#comparison-page {
    .get-started-container {
      .get-started-content {
        .get-started-buttons {
          max-width: 900px;
        }
      }
    }
    .get-started-image {
      img {
        width: 300px;
        height: auto;

        @include mobile {
          width: 200px;
          margin: 2rem auto 0;
        }
      }
    }
    img#bikers {
      position: relative;
      margin: 2rem 0 0 -9rem;
      z-index: -1;

      @include tablet {
        margin: 2rem 0 0 0;
      }

      @include desktop {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 6rem;
      }
    }

    #comparison-top {
      padding: 5rem 1rem;
      margin-top: 5rem;

      @include desktop {
        padding: 5rem 9rem;
      }

        #top_content {
            display: flex;
            flex-direction: column;

            #first h1 {
              // text-decoration: underline;
              margin: 0;
            }

            #second h2 {
              margin: 0;
              font-weight: 400;
            }
            
            #third {
                .btn-consult {
                    width: fit-content;
                    background: var(--linearGradient);
                    background-color: var(--quaternary);
                    color: var(--white);
                    padding: 1rem;
                    margin-top: 4rem;
                    border-radius: 0.25rem;
                    border: 1px solid var(--quinary);
                    cursor: pointer;
                    font-size: 1rem;
                    text-transform: uppercase;
                    line-height: 1.5rem;
                }
            }
        }
    }
    
    #other-platforms {
      background-color: var(--white);
      margin: -18rem 0 0 0;

      @include desktop {
        padding: 5rem;
        margin: 7rem 0;
      }

      .platforms-flex {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 2rem;
        padding-top: 2rem;

        @include desktop {
          padding-top: 0;
        }

        .platforms-tables {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem 2rem 6rem 2rem;
          gap: 8rem;
          background-color: var(--lightPurple);
          border-radius: 1rem;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

          .comp-table {
            @include desktop {
              display: block;
              width: 100%;

              h2 {
                text-align: center;
              }
  
              table {
                min-width: 800px;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;
  
                thead th {
                  white-space: nowrap;
                  padding: 0 2rem;
                  
                  &:nth-child(1),
                  &:nth-child(2) {
                    position: sticky;
                    left: 0;
                    background-color: var(--lightPurple);
                    z-index: 2;
                  }
                  
                  &:nth-child(2) {
                    left: 200px;
                  }
                }
                tbody td {
                  white-space: nowrap;
                  border-bottom: 1px solid var(--quaternary);
                  &:nth-child(1),
                  &:nth-child(2) {
                    position: sticky;
                    left: 0;
                    background-color: var(--lightPurple);
                    z-index: 2;
                  }
                  
                  &:nth-child(2) {
                    left: 200px;
                  }
  
                  &.feature {
                    font-size: 18px;
                    line-height: 2.5rem;
                    text-align: left;
                    @include mobile {
                      padding-left: 1rem;
                    }
                  }
                  span.tick {
                    font-size: 1.4rem;
                    color: var(--sixth);
                    font-family: "Oswald"
                  }
                }
              }
            }

            display: block;
            width: 100%;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: thick;  // For Firefox, use 'thick' for a thicker scrollbar
            scrollbar-color: var(--quaternary) var(--lightPurple);  // For Firefox

            // Webkit scrollbar styling (Chrome, Safari, newer Edge)
            &::-webkit-scrollbar {
              height: 20px;  // Increased height for better visibility
              width: 20px;
            }

            &::-webkit-scrollbar-track {
              background: var(--lightPurple);
              border-radius: 0.5rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--quaternary);
              border-radius: 0.5rem;
              border: 10px solid var(--sixth);
            }

            table {
              min-width: 800px;
              width: 100%;
              
              thead th {
                white-space: nowrap;
                padding: 0 2rem;
              }

              tbody td {
                white-space: nowrap;
                &.feature {
                  font-size: 18px;
                  line-height: 2.5rem;
                  text-align: left;
                  padding-left: 1rem;
                }
              }
            }
          }

          #compareimage {
            display: none;
          }
        }
        @include mobile {
          margin: 1rem;
        }
      }
    }

    #why-chalet {
      /* Top Section */
      .top-section {
        background-color: var(--tertiary); /* Blueish color */
        color: white;
        text-align: center;
        padding: 2rem 1rem;
        min-height: 350px;
      }

      .top-section h2 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        color: white;
      }

      .top-section p {
        font-size: 20px;
        margin: 3.5rem 0 0 0;
        color: white;

        @include desktop {
          font-size: 26px;
        }
      }

      /* Content Section */
      .absolute-section {
        margin-top: -10rem;
        background-color: var(--tertiary);

        .content-section {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 1rem;
          padding: 2rem;
        }
  
        .card {
          min-height: 380px;
          background-color: var(--white); /* Light purple color */
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 1.5rem;
          text-align: left;
        }
  
        .card-number {
          color: white;
          font-size: 1.5rem;
          font-weight: bold;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          text-align: center;
          background-color: #F06F7C;
        }
  
        .card h3 {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }
  
        .card p {
          font-size: 1.2rem;
          color: #555;
        }
      }

      /* Bottom Section */
      .bottom-section {
        background-color: var(--tertiary); /* Light purplish color */
        min-height: 350px;
        margin-top: -15rem;

        @include desktop {
          background-color: var(--lightPurple);
        }
      }

    }

    #why-better {
      background-color: var(--white);
      padding: 5rem 1rem;
      
      @include desktop {
        padding: 5rem 9rem;
      }

      .better-content {
        max-width: 1200px;
        margin: 0 auto;

        h2 {
          text-align: center;
          margin-bottom: 3rem;
          font-size: 2rem;
          color: var(--black);
        }

        .comparison-grid {
          display: grid;
          gap: 2rem;
          
          @include desktop {
            grid-template-columns: repeat(3, 1fr);
          }

          .comparison-item {
            background: var(--lightPurple);
            padding: 2rem;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            h3 {
              color: var(--black);
              margin-bottom: 1rem;
              font-size: 1.5rem;
            }

            p {
              font-size: 1.1rem;
              line-height: 1.6;
              color: var(--sixth);
            }
          }
        }
      }
    }

    #final-choice {
      background-color: var(--lightPurple);
      padding: 5rem 1rem;
      margin-top: -1px; // Prevent any potential gaps

      @include desktop {
        padding: 7rem 9rem;
      }
      
      .choice-content {
        max-width: 900px;
        margin: 0 auto;
        text-align: center;

        h2 {
          color: var(--primary);
          margin-bottom: 2rem;
          font-size: 2rem;
        }

        p {
          font-size: 1.25rem;
          line-height: 1.6;
          color: var(--sixth);
          
          @include desktop {
            font-size: 1.4rem;
          }
        }
      }
    }
}