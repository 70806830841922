@import 'shared';

#blog_main {
  padding-top: 120px;
  background: none;

  #blog_header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -8rem;
    // border-top: 1px solid var(--primary);

    @media (min-width: $breakpoint-mobile) and (max-width: 920px) {
      margin-bottom: -2rem !important;
    }

    @include mobile {
      margin: 0;
    }

    div:nth-child(1) {
      padding: 1rem;

      @include mobile {
        padding: 0;
      }

      @include tablet {
        display: flex;
      }
    }
  }

  .search-image {
    position: relative;
    top: 70%;
    left: 0;
    z-index: -1;

    @include mobile {
      position: absolute;
      z-index: -1;
    }

    &._bg {
      z-index: -1;
    }
  }

  #location-container {
    display: flex;
    flex-direction: column;
    margin: 0px;

    @media (min-width: 1400px) {
      margin-top: 0;
    }

    @include desktop {
      display: block;
      flex-direction: unset;
      align-items: unset;
    }

    div:nth-child(2) {
      width: 80%;
      margin-top: 1rem;
      flex-direction: column;

      svg {
        height: 100%;
      }

      @include mobile {
        margin: 0 0 2rem 0;
      }

      label {
        margin: 0 !important;
      }
    }

    #search-dropdown {

      @include mobile {
        top: 70px !important;
      }
    }
  }

  #blog_landing {

    #blog_skeleton {

      #blog_intro {
        width: 100%;
        margin-bottom: 4rem;
        font-weight: 300;

        @include mobile {
          width: 100%;
        }
      }

      .featured-section {
        .featured-post:hover {
          cursor: pointer;
        }

        .featured {
          @include mobile {
            flex-direction: column;

            :nth-child(1) {
              margin-bottom: 1rem;
            }

            .featured-post {
              height: fit-content;


              .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-basis: 50%;
                height: fit-content;

                .read-more-wrapper {
                  display: contents;
                }
              }

              .image {
                flex-basis: 50%;
                height:auto;
              }
            }
          }
        }
      }
    }
  }
}

#portal-container {
  #blog-modal {
    max-width: 600px;

    @include mobile {
      width: 90vw;
      height: fit-content;
      max-width: unset;
      max-height: unset;

      input[name="managementStyle"]::placeholder {
        @media (max-width: 400px) {
          font-size: 0.95rem !important;
        }

        @include mobile {
          font-size: 1rem;
        }
      }
    }

    > button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100000;
    }

    #modal-logo {
      max-width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      margin: 1rem;
      z-index: 100000;
    }

    #modal-content {
      @include mobile {
        width: 100%;
        height: 100%;
        padding: 3rem 1rem 1.5rem 1rem;
        margin:0;
      }

      #modal-title {
        > h1 {
          @media (max-width: 400px) {
            font-size: 1.5rem;
          }
        }
      }

      #modal-main {
        #style-select {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 1rem;
          background-color: var(--white);
          border: 1px solid rgb(0 0 0 / 40%);
          border-radius: 0.25rem;
          box-shadow: 0px 0px 43px rgb(0 0 0 / 10%);
          color: var(--primary);
          font-size: 1rem;
          max-width: 100%;
          padding: 0.55rem 1rem;
        }
      }
    }

    @include tablet {
      width: 90vw;
      max-width: unset;
      max-height: unset;
    }
  }

  > div:not(#blog-modal) {
    //opacity: 0.5;
  }
}
