@import "shared";

.testimonial {
  background-size: cover;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  background: var(--white);
  border: 1px solid var(--lightPurple);
  box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
  box-sizing: border-box;
  text-align: center;
  width: 100%;

  > * {
    margin-bottom: 1rem;
  }

  > *:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }

  > Text {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  .stars {
    color: var(--sixth);
    margin: 1rem 0;
    font-size: 1.2rem;
  }

  a {
    background: #e11818;
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
}

#left-content {
  text-align: left;
  max-width: 850px;
}

.how-it-works-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.9rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2.9rem;

  @include mobile {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 0rem;
  }

  .timeline {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 5.8rem;

    @include mobile {
      // gap: 4.8rem;
    }

    .step {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .step-number {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background: var(--white);
        border: 2px solid var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: var(--primary);
        transition: all 0.4s ease-in-out;
        font-size: 1.44rem;

        @include mobile {
          width: 48px;
          height: 48px;
          font-size: 1.2rem;
        }
      }

      .connector {
        width: 2px;
        height: 270px;
        background: var(--primary);
        margin-top: 0rem;
        transition: all 0.4s ease-in-out;

        @include mobile {
          width: 2.4px;
          height: 280px;
        }
      }

      &.active {
        .step-number {
          background: var(--sixth);
          border-color: var(--sixth);
          color: var(--white);
          transform: scale(1.1);
        }

        .connector {
          background: var(--sixth);
        }
      }

      &:not(.active) {
        opacity: 0.7;
        transition: opacity 0.4s ease-in-out;
      }
    }
  }

  .steps-content {
    .step-item {
      display: grid;
      grid-template-columns: 115px 1fr;
      gap: 2.9rem;
      margin-bottom: 5.8rem;

      @include mobile {
        grid-template-columns: 1fr;
        gap: 0rem;
        margin-bottom: 3rem;
      }

      .step-icon {
        img {
          width: 115px;
          height: auto;
          // object-fit: contain;
          text-align: center;

          @include mobile {
            width: 75px;
            height: auto;
          }
        }
      }

      .step-text {
        h3 {
          font-size: 2.16rem;
          margin-top: 0.72rem;
          font-weight: 600;
          color: var(--primary);
          margin-bottom: 0.72rem;

          @include mobile {
            font-size: 1.5rem;
            margin-bottom: 0.6rem;
          }
        }

        p {
          font-size: 1.44rem;
          margin-bottom: 1.44rem;
          line-height: 2.16;

          @include mobile {
            font-size: 1rem;
            margin-bottom: 1.2rem;
            line-height: 1.6;
            margin: 0.5rem 0;
          }
        }

        .step-link {
          color: var(--sixth);
          text-decoration: none;
          font-weight: 600;
          font-size: 1.58rem;
          background-color: var(--white);
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          border: 1px solid var(--sixth);
          &:hover {
            background-color: var(--sixth);
            color: var(--white);
          }

          @include mobile {
            font-size: 1rem;
            padding: 0.3rem 0.7rem;
          }
        }
      }
    }
  }
}

.connect-with-realtor_button {
  margin-top: 2rem;
  padding: 1rem 2rem;
  background: var(--quinary);
  color: var(--white);
  border: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  display: block;
  margin: 1rem auto;

  &:hover {
    background: var(--quinary);
  }
}
.tools-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 85vw;
  margin: 0 auto 5rem auto;
  padding: 0 1rem;

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mobile {
    grid-template-columns: 1fr;
    padding: 0rem;
  }

  .tool-card {
    background: var(--white);
    border-radius: 0.5rem;
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    // border: 1px solid var(--sixth);
    border: none;
    box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: var(--lightPurple);
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-bottom: 1rem;
    }

    .tool-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;

      h3 {
        color: var(--primary);
        margin-bottom: 0.5rem;
        font-size: 1.7rem;
        font-weight: 700;
      }

      p {
        color: var(--primary);
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.5;
        flex: 1;
      }

      a {
        color: var(--sixth);
        text-decoration: none;
        font-weight: 700;
        font-size: 1.2rem;
        display: block;
        margin-top: auto;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.get-started-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  align-items: center;

  @include mobile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .get-started-content {
    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      color: var(--white);
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.5rem;
      color: var(--white);
      margin-bottom: 2rem;
    }

    .get-started-buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      max-width: 400px;

      @include mobile {
        margin: 0 auto;
        flex-direction: column;
      }

      button {
        width: 100%;
        background: var(--white);
        color: var(--primary);
        border: 1px solid var(--primary);
        padding: 0.8rem 2rem;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: var(--lightPurple);
        }
      }
    }
  }

  .get-started-image {
    img {
      width: 400px;
      height: auto;

      @include mobile {
        width: 200px;
        margin: 2rem auto 0;
      }
    }
  }
}

.slick-prev,
.slick-next {
  font-size: 20px;
  color: #e11818;
}
.slick-track {
  margin-bottom: 2rem;
}

.slick-prev:hover,
.slick-next:hover {
  color: #000;
}
.slick-dots {
  bottom: -2rem;
  padding: 1rem;
}
.slick-list {
  max-width: 100vw;
  padding: 0px !important;
  margin-bottom: 4rem;
}

.slick-dots li button:before {
  color: #666;
  font-size: 14px;
}

.slick-dots li.slick-active button:before {
  color: #000;
}
#home-statistics {
  max-width: unset;
  display: flex;
  justify-content: space-evenly;

  @include mobile {
    flex-direction: column;
    justify-content: unset;
    margin-top: 0rem;
  }

  @include tablet {
    justify-content: space-between;
  }

  .stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;

    #highlight {
      font-size: 5rem;
      font-weight: 500;
      color: var(--white);
      margin: -10px 0;

      @include tablet {
        font-size: 3rem;
      }

      @include mobile {
        font-size: 3.8rem;
      }
    }

    #alt-red {
      color: var(--white);
      font-size: 2rem;
      font-weight: 500;

      @include tablet {
        font-size: 1.2rem;
      }

      @include mobile {
        font-size: 1.6rem;
        text-align: center;
      }
    }

    #alt-black {
      color: var(--white);
      font-size: 2rem;
      font-weight: 500;

      @include tablet {
        font-size: 1.2rem;
      }

      @include mobile {
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }

  #verdiv {
    @include mobile {
      display: none !important;
    }
  }

  #hordiv {
    display: none !important;

    @include mobile {
      display: block !important;
    }
  }
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 95vw;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .testimonials-container {
    grid-template-columns: 1fr;
    display: block;
    
  }
}
