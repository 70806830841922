@import 'shared';
// Variables
$primary-color: var(--quinary);
$secondary-color: var(--primary);
$background-color: var(--white);
$text-color: var(--quaternary);
$light-gray: var(--lightPurple);
$dark-gray: var(--sixth);

// Global Styles
body {
  font-family: 'Open Sans', sans-serif;
  background-color: $background-color;
  color: $text-color;
}

// Listings Page Styles
.listings-page {
  max-width: 77.5rem; // 1240px
  margin: 0 auto;
  padding: 1.25rem; // 20px

  h1 {
    font-size: 1.75rem; // 28px
    font-weight: 700;
    margin-bottom: 1.25rem; // 20px
  }

  .search-input {
    width: 100%;
    padding: 0.75rem; // 12px
    font-size: 1rem; // 16px
    border: 1px solid $light-gray; // 1px
    border-radius: 0.25rem; // 4px
    margin-bottom: 1.25rem; // 20px
  }

  .listings-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.25rem; // 20px
  }
}

// Listing Card Styles
.listing-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: calc(25% - 0.9375rem); // 4 cards per row with gap

  @media (max-width: 75rem) { // 1200px
    width: calc(33.333% - 0.8333rem); // 3 cards per row
  }

  @media (max-width: 62rem) { // 992px
    width: calc(50% - 0.625rem); // 2 cards per row
  }

  @media (max-width: 48rem) { // 767px
    width: 100%; // 1 card per row
  }
}

.listing-card {
  background-color: var(--white);
  border-radius: 0.5rem; // 8px
  overflow: hidden;
  box-shadow: 0 0 2.6875rem rgba(0, 0, 0, 0.1); // 43px
  transition: all 0.3s ease;
  height: 31.25rem; // 500px
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.2); // 4px 8px
    transform: translateY(-0.3125rem); // -5px
  }

  .listing-info {
    padding: 1rem; // 16px
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h2 {
    font-size: 1.25rem; // 20px
    margin-bottom: 0.5rem; // 8px
    color: var(--primary);
    font-weight: 400;
  }

  .location {
    color: $dark-gray;
    margin-bottom: 0.5rem; // 8px
  }

  .price {
    font-size: 1.5rem; // 24px
    font-weight: 700;
    color: $primary-color;
    margin-bottom: 0.5rem; // 8px
  }

  .revenue {
    color: $secondary-color;
    margin-bottom: 0.5rem; // 8px
  }

  .details {
    color: $dark-gray;
    margin-bottom: 1rem; // 16px
  }

  @media (max-width: 48rem) { // 767px
    height: auto;
    min-height: 31.25rem; // 500px
  }
}

// Button Styles
.view-details-button, .contact-agent-button {
  display: block;
  width: 100%;
  padding: 0.75rem; // 12px
  background-color: $primary-color;
  color: var(--white);
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 0.25rem; // 4px
  font-size: 1rem; // 16px
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--linearGradient)
  }
}

// Individual Listing Page Styles
.individual-listing-page {
  max-width: 95vw;
  margin: 6.75rem auto 0; // 108px
  padding: 1.25rem; // 20px
  font-family: Arial, sans-serif;
  background: var(--white);
  border-radius: 0.5rem; // 8px

  .back-link {
    display: inline-block;
    margin-bottom: 1.25rem; // 20px
    text-decoration: none;
    color: var(--quaternary);
    font-weight: bold;
  }

  .listing-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.25rem; // 20px

    h1 {
      font-size: 1.5rem; // 24px
      margin: 0;
    }

    .header-actions {
      display: flex;
      gap: 0.625rem; // 10px

      .action-button {
        padding: 0.5rem 1rem; // 8px 16px
        background-color: var(--lightPurple);
        border: 1px solid var(--quinary); // 1px
        border-radius: 0.625rem; // 1rem
        cursor: pointer;
        font-size: 0.75rem; // 1.2rem

        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
  }

  .image-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem; // 8px
    height: 25rem; // 400px
    overflow: hidden;
    border-radius: 0.75rem; // 12px
    position: relative;

    .gallery-image {
      overflow: hidden;
      cursor: pointer;
      transition: opacity 0.3s ease;
      position: relative;
      border: none;
      background: none;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.3125rem; // 5px
      }

      &.main-image {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      &:nth-child(2) {
        grid-column: 3;
        grid-row: 1;
      }

      &:nth-child(3) {
        grid-column: 4;
        grid-row: 1;
      }

      &:nth-child(4) {
        grid-column: 3;
        grid-row: 2;
      }

      &:nth-child(5) {
        grid-column: 4;
        grid-row: 2;
      }
    }

    .see-all-photos {
      position: absolute;
      bottom: 1.25rem; // 20px
      right: 1.25rem; // 20px
      background-color: rgba(255, 255, 255, 0.8);
      color: #333;
      padding: 0.5rem 1rem; // 8px 16px
      border-radius: 1.25rem; // 20px
      font-size: 0.875rem; // 14px
      font-weight: bold;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }

  .listing-details {
    display: flex;
    gap: 2.5rem; // 40px
    margin-bottom: 2.5rem; // 40px
    background: var(--white);

    .main-info {
      flex: 2;

      h2 {
        font-size: 1.25rem; // 2rem
        color: var(--primary);
        margin-bottom: 0.625rem; // 10px
      }

      .property-stats {
        display: flex;
        gap: 1.25rem; // 20px
        margin-bottom: 0.625rem; // 10px
        font-size: 1rem; // 16px
        span {
          font-size: 1.125rem; // 1.5rem
        }
      }

      .property-features {
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem; // 10px
        margin-top: 1.25rem; // 20px
        margin-bottom: 1.25rem; // 20px
        .feature {
          background: var(--lightPurple);
          padding: 0.5rem 1rem; // 8px 16px
          border-radius: 0.3125rem; // 0.5rem
          color: var(--white);
          font-size: 1.125rem; // 1.5rem
          span {
            color: var(--quinary);
          }
        }
      }
      p.location {
        font-size: 1.125rem; // 1.5rem
        color: var(--sixth);
        font-weight: 400;
      }
    }

    .contact-form-container {
      flex: 1;
      max-width: 33.33%;
    }

    .contact-seller-button {
      display: none;
    }

    .modal-overlay {
      display: none;
    }

    .contact-form {
      background-color: var(--white);
      box-shadow: 0 0 2.6875rem rgba(0, 0, 0, 0.1); // 43px
      padding: 1.25rem; // 20px
      border-radius: 0.5rem; // 8px
      margin-top: 1.5rem; // 2rem

      h3 {
        margin-top: 0;
        margin-bottom: 1.25rem; // 20px
        font-size: 1.125rem; // 1.5rem
        font-weight: 300;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 0.625rem; // 10px

        input, textarea {
          padding: 0.625rem; // 10px
          border: 1px solid #ccc; // 1px
          border-radius: 0.25rem; // 4px
        }

        textarea {
          height: 6.25rem; // 100px
        }

        .submit-button {
          background-color: var(--quinary);
          color: var(--white);
          border: none;
          font-size: 1.2rem;
          text-transform: uppercase;
          padding: 0.75rem; // 12px
          border-radius: 0.25rem; // 4px
          cursor: pointer;
          font-weight: 400;

          &:hover {
            background-color: #e31c5f;
          }
        }
      }

      .form-disclaimer {
        font-size: 0.75rem; // 12px
        color: #666;
        margin-top: 0.625rem; // 10px
      }
    }
    @media (max-width: 767px) { 
      flex-direction: column;
    
      .main-info, .contact-form-container {
        flex: 1;
        max-width: 100%;
      }
    
      .contact-seller-button {
        display: block;
        width: 100%;
        padding: 1rem; // 12px
        background: var(--linearGradient); // Changed to var(--primary)
        color: var(--white);
        text-transform: uppercase;
        border-radius: 0.5rem;
        text-align: center;
        text-decoration: none;
        border: none;
        font-size: 1.2rem; // 16px
        font-weight: 400;
        cursor: pointer;
        transition: background-color 0.3s ease;
        
        // New styles for fixed positioning
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000; // Ensure it's above other content
        margin: 0; // Remove top margin
    
        &:hover {
          background-color: (var(--linearGradient), 10%); // Darken on hover
        }
      }
      .contact-form{
        display: none;
      }
      .contact-form-mobile {
        display: block;
        background-color: var(--white);
        // box-shadow: 0 0 2.6875rem rgba(0, 0, 0, 0.1); // 43px
        // padding: 1.25rem; // 20px
        border-radius: 0.5rem; // 8px
        margin-top: 1.5rem; // 2rem
        h3 {
          margin-top: 0;
          margin-bottom: 1.25rem; // 20px
          font-size: 1.125rem; // 1.5rem
          font-weight: 300;
        }
  
        form {
          display: flex;
          flex-direction: column;
          gap: 0.625rem; // 10px
  
          input, textarea {
            padding: 0.625rem; // 10px
            border: 1px solid #ccc; // 1px
            border-radius: 0.25rem; // 4px
          }
  
          textarea {
            height: 6.25rem; // 100px
          }
  
          .submit-button {
            background-color: var(--quinary);
            color: var(--white);
            border: none;
            font-size: 1rem;
            padding: 0.75rem; // 12px
            border-radius: 0.25rem; // 4px
            cursor: pointer;
            font-weight: 400;
  
            &:hover {
              background-color: #e31c5f;
            }
          }
        }
  
        .form-disclaimer {
          font-size: 0.75rem; // 12px
          color: #666;
          margin-top: 0.625rem; // 10px
        }
      }
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;
        height: 100vh; // Add this line to ensure full height
      }
    
      .modal-content {
        background-color:var(--white);
        padding: 1.25rem;
        border-radius: 0.5rem;
        width: 90%;
        max-width: 400px;
        position: relative;
        max-height: 90vh; // Add this line to limit height
        overflow-y: auto; // Add this line to enable scrolling if needed
      }
    
      .close-modal {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        color: var(--quaternary);
      }
    }
  }

  .projected-returns {
    // background-color: var(--lightPurple);
    padding: 0;
    border-radius: 0.5rem; // 8px
    margin-bottom: 0.625rem; // 10px
    margin-top: 2rem; // 3rem

    h3 {
      margin-top: 0;
      margin-bottom: 1.25rem; // 20px
      // padding-left: 0; 
      font-size: 1.0625rem; // 1.7rem
      font-weight: 400;
    }

    .returns-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.625rem; // 10px

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--sixth); // 1px
        padding: 0.75rem; // 1rem
        border-radius: 0.3125rem; // 0.5rem
        text-align: center;

        span:first-child {
          font-size: 0.875rem; // 14px
          color: var(--primary);
          margin-bottom: 0.3125rem; // 5px
        }

        span:last-child {
          font-size: 1.5rem; // 24px
          font-weight: bold;
          color: var(--primary);
        }
      }
    }
  }

  .listing-description {
    
    h3 {
      margin-bottom: 1.25rem; // 20px
      font-size: 1.5rem;
      font-weight: 400;
    }

    p {
      line-height: 1.6;
      font-size: 1.2rem; // 1.2rem
    }
  }
}

// Carousel Styles
// Updated Carousel Styles
.carousel-root {
  .carousel {
    .slide {
      background: none;
      
      img {
        max-height: 18.75rem; // 300px
        object-fit: cover;
      }
    }

    .control-arrow {
      opacity: 0.8;
      height: 5rem; // 80px
      width: 2.5rem; // 40px
      top: 50%;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;
      
      &:hover {
        opacity: 1;
        background: rgba(0, 0, 0, 0.5);
      }

      &::before {
        content: '';
        display: block;
        width: 0.625rem; // 10px
        height: 0.625rem; // 10px
        border-top: 0.1875rem solid var(--white); // 3px
        border-right: 0.1875rem solid var(--white); // 3px
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }

    .control-prev {
      left: 0;
      &::before {
        transform: translate(-25%, -50%) rotate(-135deg);
      }
    }

    .control-next {
      right: 0;
      &::before {
        transform: translate(-75%, -50%) rotate(45deg);
      }
    }
  }
}

.full-screen-gallery {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 1.5rem; // 24px
    padding: 0.625rem 0.9375rem; // 10px 15px
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &.prev {
      left: 0.625rem; // 10px
    }

    &.next {
      right: 0.625rem; // 10px
    }
  }
}

.image-gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-gallery-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;

  .gallery-image {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2.5rem; // Increased from 1.25rem
    cursor: pointer;
    padding: 1rem 0.75rem; // Added padding
    border-radius: 0.5rem; // Added border-radius
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &.prev { left: 1rem; @media (max-width: 768px) { left: 0.5rem !important; font-size: 34px !important; height: 40px !important; width: 40px !important; } } // Increased from 0.625rem
    &.next { right: 1rem; @media (max-width: 768px) { right: 0.5rem !important; font-size: 34px !important; height: 40px !important; width: 40px !important; } } // Increased from 0.625rem
  }

  .close-button {
    position: absolute;
    top: 1rem; // Increased from 0.625rem
    right: 1rem; // Increased from 0.625rem
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem; // Increased from 1.25rem
    cursor: pointer;
    width: 3rem; // Added fixed width
    height: 3rem; // Added fixed height
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; // Made it circular
    transition: background-color 0.3s ease;
    @media (max-width: 800px) {
      font-size: 34px !important; height: 40px !important; width: 40px !important; 
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
    
  }
}

.lender-cta {
  background-color: var(--white);
  border: 1px solid var(--gray); // 1px
  border-radius: 0.1875rem; // 0.25rem
  padding: 1.125rem; // 1.5rem
  margin-top: 1.5rem; // 2rem
  box-shadow: 0 0 2.6875rem rgba(0, 0, 0, 0.1); // 43px

  h3 {
    font-size: 1.5rem; // 1.5rem
    margin-bottom: 1rem; // 1rem
    font-weight: 400;
    color: var(--primary);
  }

  p {
    font-size: 1.2rem; // 1rem
    margin-bottom: 1rem; // 1rem
    color: var(--primary);
  }

  .cta-button {
    background: var(--linearGradient);
    color: var(--white);
    border: none;
    padding: 0.75rem 1.5rem; // 0.75rem 1.5rem
    font-size: 1.2rem; // 1rem
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 0.25rem; // 0.25rem
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  .cta-disclaimer {
    font-size: 1.2rem; // 0.875rem
    color: var(--sixth);
    margin-top: 1rem; // 0.75rem
  }
}

.analyze-market-button {
  display: block;
  width: 100%;
  padding: 0.625rem; // 10px
  margin-top: 0;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 0.25rem; // 4px
  font-size: 1rem; // 16px
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: var(--linearGradient);
  }
}

// Thank You Modal Styles
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content.thank-you-modal {
  background-color: var(--white);
  padding: 1.25rem; // 20px
  border-radius: 0.5rem; // 8px
  text-align: center;
  max-width: 90%;
  width: 25rem; // 400px

  h2 {
    color: var(--primary);
    margin-bottom: 0.625rem; // 10px
    font-size: 1.5rem; // 24px
  }

  p {
    margin-bottom: 1.25rem; // 20px
    font-size: 1rem; // 16px
    color: var(--quaternary);
  }

  button {
    background-color: var(--quinary);
    color: var(--white);
    border: none;
    padding: 0.625rem 1.25rem; // 10px 20px
    border-radius: 0.25rem; // 4px
    cursor: pointer;
    font-size: 1rem; // 16px
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--linearGradient);
    }
  }
}

