@import 'shared';

#landing-page {
  overflow-y: hidden;
  // background-color: var(--lightPink);

  // * {
  //   overflow-y: hidden;
  // }
  .testimonial {
    background-size: cover;
    border-radius: 1rem;
    padding: 2rem;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto;
    background: var(--white);
    border: 1px solid var(--lightPurple);
    box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    height: 100%;
  
    > * {
      margin-bottom: 1rem;
    }
  
    > *:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }
  
    > Text {
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
  
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 1rem;
    }
  
    .stars {
      color: var(--sixth);
      margin: 1rem 0;
      font-size: 2rem;
    }
  
    a {
      margin-top: auto;
      background: #e11818;
      color: white;
      padding: 0.8rem 1.5rem;
      border-radius: 0.25rem;
      text-decoration: none;
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
    @include mobile {
      min-height: 700px;
    }
  }
  
  
  .slick-prev,
  .slick-next {
    font-size: 20px;
    color: #e11818;
  }
  .slick-track {
    margin-bottom: 2rem;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: #000;
  }
  .slick-dots {
    bottom: -2rem;
    padding: 1rem;
  }
  .slick-list { max-width: 100vw; padding: 0px !important; margin-bottom: 4rem;} 
  
  .slick-dots li button:before {
    color: #666;
    font-size: 14px;
  }
  
  .slick-dots li.slick-active button:before {
    color: #000;
  }

  #home-top {
    height: 100vh;
    width: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;

    #top_content {
      width: 100%;
      max-width: 80vw;
      margin: 0 auto;
      padding: 4rem 2rem;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 400px;
      gap: 2rem;
      align-items: flex-start;
      margin-top: 10rem;

      @include mobile {
        grid-template-columns: 1fr;
        max-width: 100vw;
        padding: 2rem 1rem;
        margin-top: 5rem;
        gap: 1rem;
        align-content: flex-start;
        background: var(--lightPink);

        #left-content {
          width: 100%;
          
          h1 {
            font-size: 1.5rem;
            text-align: left;
          }

          h2 {
            text-align: left;
          }
        }

        #right-content {
          width: 100%;
          max-width: none;
          margin: 0;
          padding: 2rem;
        }
      }

      #left-content {
        text-align: left;
        max-width: 1000px;

        h1 {
          font-size: 2.5rem;
          font-weight: 700;
          color: var(--sixth);
          margin-bottom: 2rem;
          // text-decoration: underline;
          line-height: 1.2;
          margin-bottom: 0;

          @include mobile {
            font-size: 1.5rem;
          }
        }

        h2 {
          font-size: 1.4rem;
          font-weight: 400;
          color: var(--primary);
          margin-bottom: 3rem;
          line-height: 1.5;

          @include mobile {
            font-size: 1rem;
            margin-bottom: 1rem;
          }
        }
      }

      #right-content {
        // background: var(--white);
        background: var(--lightPurple);
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid #8684847a;
        box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
        z-index: 300;
        @include mobile {
          padding: 2rem;
          box-shadow: 0px 1.4rem 6rem -2rem rgba(108, 129, 171, 0.8);
        }
        h3 {
          font-size: 1.7rem;
          font-weight: 600;
          color: var(--sixth);
          text-align: center;
          margin-bottom: 1rem;
          margin-top: 0rem;
        }

        p {
          font-size: 1.1rem;
          color: var(--primary);
          margin-bottom: 2rem;
          text-align: center;
          @include mobile {
            display: none;
          }
        }

        #button-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          button {
            width: 100%;
            background: var(--white);
            color: var(--primary);
            border: 1px solid var(--primary);
            padding: 0.5rem 2rem;
            font-size: 1.3rem;
            font-weight: 500;
            border-radius: 0.5rem;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              background: var(--lightPurple);
            }
          }
        }
       
      }
    }

    @include tablet {
      #top_content {
        grid-template-columns: 1fr;
        padding: 2rem 1rem;

        #right-content {
          width: 100%;
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }
  }

  #home-statistics {
    max-width: unset;
    display: flex;
    justify-content: space-evenly;
  
    @include mobile {
      flex-direction: column;
      justify-content: unset;
      // margin-top: -1rem;
      margin-top: 0;
    }
  
    @include tablet {
      justify-content: space-between;
    }
  
    .stats {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3rem 0;
  
      #highlight {
        font-size: 5rem;
        font-weight: 500;
        color: var(--white);
        margin: -10px 0;
  
        @include tablet {
          font-size: 3rem;
        }
  
        @include mobile {
          font-size: 3.8rem;
        }
      }
  
      #alt-red {
        color: var(--white);
        font-size: 2rem;
        font-weight: 500;
  
        @include tablet {
          font-size: 1.2rem;
        }
  
        @include mobile {
          font-size: 1.6rem;
          text-align: center;
        }
      }
  
      #alt-black {
        color: var(--white);
        font-size: 2rem;
        font-weight: 500;
  
        @include tablet {
          font-size: 1.2rem;
        }
  
        @include mobile {
          font-size: 1.6rem;
          text-align: center;
        }
      }
    }
  
    #verdiv {
      @include mobile {
        display: none !important;
      }
    }
  
    #hordiv {
      display: none !important;
  
      @include mobile {
        display: block !important;
      }
    }
  }
  
  #home-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    background-color: var(--white);

    .bulletpoint {
      font-weight: 500;
      z-index: 5;
      pointer-events: auto;

      @include mobile {
        position: unset;
        padding: 0.5rem 0 !important;
      }
    }

    #one {
      padding-bottom: 1rem;
      margin-top: 10rem;

      @include tablet {
        padding-bottom: 3rem;
      }
    }

    #five {
      padding-top: 1rem;
      margin-bottom: 3rem;

      @include tablet {
        padding-top: 3rem;
      }
    }

    img[alt="decoration"] {
      @include mobile {
        display: none;
      }
    }

    #home-about__desc {
      width: 60%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 2rem auto;

      @include mobile {
        width: 100%;
      }

      .about-text {
        padding: 0;
        font-size: 1.5rem;
        text-align: center;
      }

      hr.hr {
        border: 0;
        height: 1px;
        background-color: var(--quaternary);
        margin: 2.5rem 10rem;
      }
    }

    #home-about__service {
      width: 60vw;
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2rem;

      @include mobile {
        flex-direction: column;
        align-items: unset;
        padding: 0;
        width: 100%;
      }

      @include tablet {
        flex-direction: column;
        padding: 0;
        width: 100%;
      }

      #div-top {
        margin-right: 0;

        @include desktop {
          margin-right: -2rem;
        }

        @include tablet {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        #six {
          padding-top: 8rem;
        }
  
        #seven {
          margin-right: 3rem;
        }
  
        #div-eight{
          display: block;

          @include desktop {
            display: flex;
            justify-content: flex-end;
          }
  
          #eight {
            padding-bottom: 8rem;            
          }
        }
      }

      #div-bot {
        margin-left: 0;

        @include desktop {
          margin-left: -2rem;
        }

        @include tablet {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
        }

        #two {
          padding-bottom: 8rem;
        }
  
        #three {
          margin-left: 3rem;

          @include mobile {
            margin: 0;
          }
        }
  
        #four {
          padding-top: 8rem;
        }
      }
    }

    #div-outside {
      @include mobile {
        align-self: flex-start;
      }

      @include tablet {
        align-self: flex-start

      }
    }
  }

  #home-guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 0;

    >h1 {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    #home-about__split {
      width: 90%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;

      @include desktop {
        flex-direction: row;
        align-items: flex-start;
      }

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        max-width: 100%;

        img {
          width: 80%;
          aspect-ratio: 1/1.1;
          padding-bottom: 1rem;

          @include tablet {
            max-width: 50%;
          }
        }

        .text {
          max-width: 80%;

          h1 {
            color: var(--quaternary);
            font-size: 1.5rem;
            font-weight: 500;
          }

          span {
            text-align: center;
          }
        }
      }
    }

    .btn-consult {
      margin: 2rem 0 12rem 0;
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 1.5rem;
      max-width: 100%;
      min-height: 4rem;
      line-height: 2rem;
      align-items: center;
      background-color: var(--quinary);
      border: none;
      border-radius: 0.25rem;
      color: var(--white);
      cursor: pointer;
      display: flex;
      font-weight: 400;
      height: max-content;
      justify-content: center;
      padding: 0.75rem 1rem;

      @include desktop {
        max-width: 500px;
        margin-bottom: 6rem;
      }
      @include mobile {
        max-width: 80%;
      }
    }
  }

  #home-markets {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;

    @include mobile {
      > img[alt="decoration"]:nth-child(1) {
        left: -6rem !important
      }

      > img[alt="decoration"]:nth-child(2) {
        right: -7rem !important
      }
    }


    #home-markets__header {
      font-weight: 500;
    }

    #home-markets__description {
      max-width: 50%;
      text-align: center;
      font-size: 1.6rem;
      font-weight: normal;

      @include mobile {
        max-width: 100%;
      }
    }

    #home-markets__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;

      @include desktop {
        flex-direction: row;
        max-width: 70%;
      }

      #left-main {
        flex-basis: 60%;
        max-width: 100%;

        >img {
          max-width: 99.2%;
          padding: 0.4rem;
          background-color: var(--white);
          box-shadow: 8px 8px 0px 0px var(--quaternary);

          @include mobile {
            max-width: 98.5%;
          }
        }
      }

      #right-main {
        flex-basis: 40%;
        max-width: 100%;
        padding: 2rem;

        @include tablet {
          max-width: 80%;
          justify-content: center;
        }

        input {
          padding: 0.7rem;
          background-color: var(--lightPurple);
        }

        * {
          width: 100%;
        }

        #disclaimer {
          font-size: 0.8rem;
          line-height:0%;
          padding: 0;
        }

        #free-download:disabled {
          background-color: var(--fourth);
          cursor: not-allowed;
        }

        button {
          margin-top: 2.5rem;
          padding: 1rem;
        }
      }
    }
  }
  
  #home-top__background {
    height: 100vh;
  }

  * span.bold {
    font-weight: 500;
  }

  .tools-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    max-width: 100vw;
    margin: 0 auto 5rem auto;
    padding: 0 1rem;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: 1fr;
      padding: 0rem;
    }

    .tool-card {
      background: var(--white);
      border-radius: 0.5rem;
      padding: 2.5rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      border: 1px solid var(--lightPurple);
      box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background: var(--lightPurple);
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 1rem;
      }

      .tool-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        h3 {
          color: var(--primary);
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-weight: 500;
        }

        p {
          color: var(--primary);
          margin-bottom: 1rem;
          font-size: 1rem;
          line-height: 1.5;
          flex: 1;
        }

        a {
          color: var(--sixth);
          text-decoration: none;
          font-weight: 700;
          font-size: 1.2rem;
          display: block;
          margin-top: auto;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .get-started-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    align-items: center;

    @include mobile {
      grid-template-columns: 1fr;
      text-align: center;
    }

    .get-started-content {
      h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--white);
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.2rem;
        color: var(--white);
        margin-bottom: 2rem;
      }

      .get-started-buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        max-width: 100%;

        @include mobile {
          margin: 0 auto;
          flex-direction: column;
        }

        button {
          width: 100%;
          background: var(--white);
          color: var(--primary);
          border: 1px solid var(--primary);
          padding: 0.8rem 2rem;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background: var(--lightPurple);
          }
        }
      }
    }

    .get-started-image {
      img {
        width: 300px;
        height: auto;

        @include mobile {
          width: 200px;
          margin: 2rem auto 0;
        }
      }
    }
  }

  .how-it-works-container {
    display: grid;
    grid-template-columns: 115px 1fr;
    gap: 2.9rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2.9rem;

    @include mobile {
      grid-template-columns: 72px 1fr;
      gap: 0.5rem;
      padding: 0rem;
    }

    .timeline {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 5.8rem;

      @include mobile {
        // gap: 4.8rem;
      }

      .step {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-number {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          background: var(--white);
          border: 2px solid var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          color: var(--primary);
          transition: all 0.4s ease-in-out;
          font-size: 1.44rem;

          @include mobile {
            width: 48px;
            height: 48px;
            font-size: 1.2rem;
          }
        }

        .connector {
          width: 2px;
          height: 270px;
          background: var(--primary);
          margin-top: 0rem;
          transition: all 0.4s ease-in-out;

          @include mobile {
            width: 2.4px;
            height: 280px;
          }
        }

        &.active {
          .step-number {
            background: var(--sixth);
            border-color: var(--sixth);
            color: var(--white);
            transform: scale(1.1);
          }

          .connector {
            background: var(--sixth);
          }
        }

        &:not(.active) {
          opacity: 0.7;
          transition: opacity 0.4s ease-in-out;
        }
      }
    }

    .steps-content {
      .step-item {
        display: grid;
        grid-template-columns: 115px 1fr;
        gap: 2.9rem;
        margin-bottom: 5.8rem;

        @include mobile {
          grid-template-columns: 1fr;
          gap: 0rem;
          margin-bottom: 3rem;
        }

        .step-icon {
          img {
            width: 115px;
            height: auto;
            // object-fit: contain;
            text-align: center;

            @include mobile {
              width: 75px;
              height: auto;
            }
          }
        }

        .step-text {
          h3 {
            font-size: 2.16rem;
            margin-top: 0.72rem;
            font-weight: 600;
            color: var(--primary);
            margin-bottom: 0.72rem;

            @include mobile {
              font-size: 1.5rem;
              margin-bottom: 0.6rem;
            }
          }

          p {
            font-size: 1.44rem;
            margin-bottom: 1.44rem;
            line-height: 2.16;

            @include mobile {
              font-size: 1rem;
              margin-bottom: 1.2rem;
              line-height: 1.6;
              margin: 0.5rem 0;
            }
          }

          .step-link {
            color: var(--sixth);
            text-decoration: none;
            font-weight: 600;
            font-size: 1.58rem;
            background-color: var(--white);
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            border: 1px solid var(--sixth);
            &:hover {
              background-color: var(--sixth);
              color: var(--white);
            }

            @include mobile {
              font-size: 1rem;
              padding: 0.3rem 0.7rem;
            }
          }
        }
      }
    }
  }
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 0rem;
  
  @include mobile {
    grid-template-columns: 1fr;
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    min-height: 600px;
    position: relative;
    padding-bottom: 80px;
    
    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 1rem;
    }
    
    Header {
      padding-bottom: 0.5rem;
      text-align: center;
    }
    
    Text {
      flex-grow: 1;
      margin-bottom: 1rem;
    }
    
    .stars {
      margin: 1rem 0;
    }
    
    a {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 4rem);
      margin: 0 auto;
    }
  }
}
