
.multiSelectContainer {
  position: relative;
  width: 200px;
}

.prefix {
  color: var(--quaternary);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  margin-right: 0.25rem;
}

.inputField {
  border: 1px solid var(--gray);
  border-radius: 5px;
  // box-shadow: 0 4px 4px rgba(47, 53, 75, 0.2);
  box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px){
    padding: 0.25rem;
  }
}

.inputField input {
  border: none;
  color: var(--primary);
  font-size: 1.25rem;
  line-height: 1.5rem;
  outline: none;
  width: 100%;
  cursor: pointer;
  background-color: unset;
  @media screen and (max-width: 768px){
    font-size: 1rem;
  }
  &::placeholder {
    color: var(--primary);
    opacity: 1;
  }
}

.inputField .arrow {
  margin-left: 8px;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.inputField .arrow.down {
  border-top: 6px solid var(--quaternary);
}

.inputField .arrow.up {
  border-bottom: 6px solid var(--quaternary);
}

.dropdown {
  animation: dissolveIn 322ms ease-in-out forwards;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid var(--linearGradient);
  border-top: none;
  border-radius: 0.25rem;
  background: var(--linearGradient);
  z-index: 1000;
  max-height: 256px;
  overflow-y: hidden;

  @keyframes dissolveIn {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1;
    }
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.optionLabel {
  padding: 8px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;

  .optionSpan {
    margin-left: 8px;
    color: var(--white);
  }

  &:hover {
    background-color: var(--fourth);
  }
}

.applyButton {
  align-items: center;
  background: var(--white);
  border: none;
  border-radius: 0.25rem;
  color: var(--primary);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  line-height: 1.5rem;
  max-width: max-content;
  margin: 0.5rem auto;
  padding: 0.25rem 2rem;

  &:hover {
    background-color: var(--lightBlue);
  }
}

.selectedOptions {
  margin-top: 16px;
}
