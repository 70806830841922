@import "shared";

.get-started-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    align-items: center;
  
    @include mobile {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    .get-started-content {
      h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--white);
        margin-bottom: 1rem;
      }
  
      p {
        font-size: 1.3rem;
        color: var(--white);
        margin-bottom: 2rem;
      }
  
      .get-started-buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        max-width: 400px;
  
        @include mobile {
          margin: 0 auto;
          flex-direction: column;
        }
  
        button {
          width: 100%;
          background: var(--white);
          color: var(--primary);
          border: 1px solid var(--primary);
          padding: 0.8rem 2rem;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease;
  
          &:hover {
            background: var(--lightPurple);
          }
        }
      }
    }
  
    .get-started-image {
      img {
        width: 400px;
        height: auto;
  
        @include mobile {
          width: 200px;
          margin: 2rem auto 0;
        }
      }
    }
  }

.connect-with-cost-seg-specialist_button_top {
    margin-top: 2rem;
    padding: 1rem 2rem;
    background: var(--sixth);
    color: var(--white);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background: var(--quinary);
    }
  }

  .tools-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 95vw;
    margin: 0 auto 5rem auto;
    padding: 0 1rem;

    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
      grid-template-columns: 1fr;
      padding: 0rem;
    }

    .tool-card {
      background: var(--white);
      border-radius: 0.5rem;
      padding: 2.5rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      border: 1px solid var(--sixth);
      box-shadow: 0px 1.4rem 6rem -2rem rgba(108,129,171,0.3);
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background: var(--lightPurple);
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 1rem;
      }

      .tool-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        h3 {
          color: var(--primary);
          margin-bottom: 0.5rem;
          font-size: 1.7rem;
          font-weight: 700;
        }

        p {
          color: var(--primary);
          margin-bottom: 1rem;
          font-size: 1rem;
          line-height: 1.5;
          flex: 1;
        }

        a {
          color: var(--sixth);
          text-decoration: none;
          font-weight: 700;
          font-size: 1.2rem;
          display: block;
          margin-top: auto;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }