@import 'shared';

#analytics-main {
  box-shadow: unset;
  max-width: unset;
  // padding-top: 0;

  @include desktop {
    padding-top: 0rem;
  }

  .analytics_intro {
    display: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include desktop {
      display: block;
    }
    // padding-bottom: 2rem;
    
    font-size: 1.2rem;
    font-weight: 300;
    
    &:nth-child(2) {
      padding-top: 0;
    }
  }

  #analytics_markets {
    .featured-section {
      .featured {
        .market-view {
          &.grid {
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 1.5rem;
            padding: 1rem;
            
            .featured-post {
              display: block;
              background: var(--white);
              border-radius: 8px;
              overflow: hidden;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              text-decoration: none;
              color: inherit;
              
              .image {
                position: relative;
                padding-top: 66.67%;
                overflow: hidden;
                
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }

              .content {
                padding: 1.5rem;
                
                .title {
                  font-size: 1.5rem;
                  font-weight: 600;
                  margin-bottom: 1rem;
                  color: var(--navy);
                }
                
                .stats {
                  display: flex;
                  flex-direction: column;
                  gap: 0.75rem;
                  
                  .revenue, .gross, .medianHomeValue {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    span {
                      &:first-child {
                        color: var(--gray-600);
                      }
                      
                      &#value {
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
              
              &:hover {
                transform: translateY(-4px);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                transition: all 0.2s ease;
              }
            }
          }

          &.list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            .market-list-item {
              display: flex;
              align-items: center;
              gap: 2rem;
              padding: 1.5rem;
              background: var(--white);
              border-radius: 8px;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              transition: all 0.2s ease;

              img {
                width: 200px;
                height: 150px;
                object-fit: cover;
                border-radius: 4px;
                flex-shrink: 0;
              }

              .market-info {
                flex: 1;
                
                .market-stats {
                  display: flex;
                  align-items: center;
                  gap: 3rem;
                  flex-wrap: wrap;
                  
                  span {
                    color: var(--gray-600);
                    font-size: 1.1rem;
                    white-space: nowrap;
                    
                    strong {
                      color: var(--navy);
                      font-weight: 600;
                      margin-left: 0.25rem;
                    }
                  }
                }
              }

              a {
                padding: 0.75rem 1.5rem;
                background: var(--primary);
                color: var(--white);
                text-decoration: none;
                border-radius: 4px;
                font-weight: 500;
                transition: all 0.2s ease;
                white-space: nowrap;
                flex-shrink: 0;

                &:hover {
                  background: var(--primary);
                  transform: translateY(-2px);
                }
              }

              &:hover {
                transform: translateY(-4px);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
              }

              @include mobile {
                flex-direction: column;
                align-items: stretch;
                gap: 1rem;

                img {
                  width: 100%;
                  height: 200px;
                }

                .market-info {
                  .market-stats {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.5rem;
                  }
                }

                a {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
