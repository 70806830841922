@import "shared";

.rental-regulations_main {
  .search-rental-regulations {
    @media (min-width: $breakpoint-mobile) and (max-width: 920px) {
      margin-bottom: -4rem !important;
    }
    
    div:nth-child(1) {
      @include tablet {
        display: flex;
      }
    }
  }

  #no-market {
    z-index: 100;

    @include desktop {
      display: block;
    }
    @include mobile {
      margin-top: 4rem;
    } 
    #skeleton {
      @include mobile {
        display: flex !important;
        flex-direction: column;
        align-items: center;

        #no-market_intro {
          width: 100%;
        }
      }
    }

    #no-market_intro {
      width: 100%;
      margin-bottom: 4rem;
      font-weight: 300;
      max-width: 1200px;
      margin: 0 auto 4rem;

      @include mobile {
        width: 100%;
      }
    }

    .featured-section {
      @include mobile {
        .featured {
          margin: 0px;

          div {
            flex-basis: 100%;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .search-image {
    position: relative;
    top: 70%;
    left: 0;
    z-index: -1;

    @include mobile {
      position: absolute;
      z-index: -1;
    }

    &._bg {
      z-index: -1;
    }
  }

  #location-container {
    display: flex;
    flex-direction: column;
    margin: 0px;

    @include desktop {
      display: block;
      flex-direction: unset;
    }

    span:nth-child(2) {
      @media (max-width: 1237px) {
        display: none;
      }
    }

    div:nth-child(3) {
      width: 80%;
      @include mobile {
        width: 100%;
      }
    }
    
  }
}
