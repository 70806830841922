@import 'shared';

.investor-guides_main {
  .search-investor-guides {
    @media (min-width: $breakpoint-mobile) and (max-width: 920px) {
      margin-bottom: -4rem !important;
    }
    
    div:nth-child(1) {
      @include tablet {
        display: flex;
      }
    }
  }

  #no-market {
    width: 100%;
    max-width: 100%;

    @include mobile {
      margin-top: 4rem;
    } 
    #no-market_intro {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 4rem;
      font-weight: 300;

      @include mobile {
        width: 100%;
      }  
    }

    .featured-section {
      @include mobile {
        .featured {
          margin: 0px;

          div {
            flex-basis: 100%;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .search-image {
    position: relative;
    top: 70%;
    left: 0;
    z-index: -1;

    @include mobile {
      position: absolute;
      z-index: -1;
    }

    &._bg {
      z-index: -1;
    } 
  }

  #location-container {
    display: flex;
    flex-direction: column;
    margin: 0px;

    @media (min-width: 1400px) {
      margin-top: 0;
    }

    @include desktop {
      display: block;
    }

    span:nth-child(2) {
      @media (max-width: 1237px) {
        display: none;
      }
    }

    div:nth-child(3) {
      width: 80%;

      @include mobile {
        width: 100%;
      }
    }
  }
}